import {
  type Image,
  type ImageProps,
  Box,
  Card,
  CheckFilledIcon,
} from '@iheartradio/web.companion';
import { type MouseEvent, type MouseEventHandler } from 'react';

import { useItemSelected } from '~app/analytics/use-item-selected';

export type GenreCardProps = {
  active?: boolean;
  image: ReturnType<typeof Image>;
  size?: number;
  subtitle?: string;
  title?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  pageName?: string;
  sectionPosition?: number;
  itemPosition?: number;
  section?: string;
  asset?: {
    id: string;
    name: string;
  };
  context?: string;
};

export const GenreCard = (props: GenreCardProps) => {
  const {
    image,
    title,
    subtitle,
    active,
    onClick,
    pageName,
    sectionPosition,
    itemPosition,
    section,
    asset,
    context,
  } = props;

  const { onItemSelected } = useItemSelected();

  return (
    <Box padding="$2 $2 $2 0">
      <Card.Root
        borderRadius="$6"
        data-test="genre-card"
        isActive={active}
        isLink
        onClick={(event: MouseEvent<HTMLDivElement>) => {
          if (pageName && asset) {
            onItemSelected({
              pageName,
              sectionPosition: sectionPosition ?? 0,
              context: context ?? 'grid',
              itemPosition: itemPosition ?? 0,
              section: section ?? 'genres',
              assets: {
                asset,
              },
            });
          }

          const target = event.target as HTMLDivElement;

          if (
            target instanceof HTMLAnchorElement ||
            target instanceof HTMLButtonElement
          ) {
            event.stopPropagation();
            return;
          }

          onClick?.(event);
        }}
        padding={{ '@large': '$6' }}
      >
        <Card.ImageContainer borderRadius="inherit">
          {image}
          {active ? (
            <CheckFilledIcon
              css={{
                position: 'absolute',
                right: '$4',
                bottom: '$4',
              }}
              fill="brand-white"
              size={{
                '@initial': '32',
                '@small': '40',
                '@medium': '48',
                '@large': '56',
                '@xlarge': '60',
              }}
            />
          ) : null}
        </Card.ImageContainer>
        {title || subtitle ? (
          <Card.Body>
            {title ? <Card.Title lines={2}>{title}</Card.Title> : null}
            {subtitle ? (
              <Card.Subtitle lines={2}>{subtitle}</Card.Subtitle>
            ) : null}
          </Card.Body>
        ) : null}
      </Card.Root>
    </Box>
  );
};

GenreCard.Image = function GenreCardImage(
  props: ImageProps & { active?: boolean },
) {
  // Don't pass `active` to the Card.Image
  const { active: _active, ...restProps } = props;
  return <Card.Image aspectRatio="16 / 9" {...restProps} />;
};
